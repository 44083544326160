import axios from "axios";

export const login = (props) => {
  return axios({
    method: 'POST',
    url: '/login',
    data: props
  });
}

export const UseList = (props) => {
  return axios({
    method: 'GET',
    url: '/users',
    params: props
  });
}

export const createUser=(props)=>{
   return axios({
     method:'POST',
     url:'/user',
     data:props
   });
}

export const changeUserPassword=(props)=>{
   return axios({
     method:'PATCH',
     url:'/user',
     data:props
   })
}

export const updateUser=(props)=>{
  return axios({
    method:'PATCH',
    url:'/user',
    data:props
  })
}

export const deleteUser=(props)=>{
  return axios({
    method:'DELETE',
    url:'/user',
    data:props
  })
}

// account

export const addAccount=(props)=>{
  return axios({
    method:'POST',
    url:'/account',
    data:props
  })
}

export const fetchAccountList=(props)=>{
  return axios({
    method:'GET',
    url:'/account',
    params:props
  })
}

export const domainDetails=(props)=>{
  return axios({
    method:'GET',
    url:'/account-details',
    params:props
  })
}

export const editAccountDetail=(props)=>{
  return axios({
    method:'PATCH',
    url:'/account',
    data:props
  })
}

export const accountDelete=(props)=>{
   return axios({
     method:'DELETE',
     url:'/account',
     data:props
   })
}

export const assessmentDelete=(props)=>{
  return axios({
    method:'DELETE',
    url:'/assessment-ref-user',
    data:props
  })
}

export const accountFreeze=(props)=>{
  return axios({
    method:'PATCH',
    url:'/updateFreezeStatus/'+props?.id,
    data:{profileFreezed: props?.profileFreezed}
  })
}

export const assessmentRestore=(props)=>{
  return axios({
    method:'PATCH',
    url:'/restoreArchivedRefUser/'+props?.id,
    
  })
}

// assessment
export const accountAssessments=(props)=>{
  return axios({
    method:'GET',
    url:'/assessment',
    params:props
  })
}

// speaker detail
export const speakerDetail=(id)=>{
  return axios({
    method:'GET',
    url:'/speaker/'+id
  })
}

// /assessment-ref-user-feedback

export const assessmentFeedback=(props)=>{
  return axios({
    method:'PATCH',
    url:'/assessment-ref-user-feedback',
    data:props
  })
}

// /merge similar account

export const mergeSimilarAccounts=(props, id)=>{
  return axios({
    method:'PATCH',
    url:'/mergeRefUsers/'+id,
    data:props
  })
}

// /assessment-ref-registered-users
export const assessmentUsers=(props)=>{
 return axios({
   method:'GET',
   url:'/assessment-ref-registered-users',
   params:props
 })
}

// post comments
export const postComments=(props)=>{
  return axios({
    method:props?.id ? 'PATCH' : 'POST',
    url:'/assessment-ref-user-comments',
    data:props
  })
}

// fetch comments
export const fetchComment=(props)=>{
  return axios({
    method:'GET',
    url:'/assessment-ref-user-comments',
    params:props
  })
}

// delete comment

export const deleteComment=(props)=>{
  return axios({
    method:'DELETE',
    url:'/assessment-ref-user-comments',
    data:props
  })
}

// fetch comments
export const fetchTagList=(props)=>{
  return axios({
    method:'GET',
    url:'/user/tags',
    params:props
  })
}

// fetch similar ref users
export const fetchSimilarRefUsers=(id)=>{
  return axios({
    method:'GET',
    url:'/getSimilarRefUsers/'+id,
  })
}

export const copyToCongress=(props)=>{
  return axios({
    method:'POST',
    url:'https://oksdevelopment.entwicklungslust.de/',
    data:props
  })
}

// delete product

export const deleteProduct=(id)=>{
  return axios({
    method:'DELETE',
    url:'/deleteProduct/'+id
  })
}

// fetch archieved products
export const fetchDeletedProducts=(params)=>{
  return axios({
    method:'GET',
    url:'/getArchivedProducts',
    params:params
  })
}

// restore archieved product

export const restoreArchievedProduct=(id)=>{
  return axios({
    method:'PATCH',
    url:'/restoreArchivedProduct/'+id
  })
}
// fetch comments
export const getSimilarRefUsers=(props)=>{
  return axios({
    method:'GET',
    url:'/getSimilarRefUsers',
    params:props
  })
}


// Approve comment
export const approveComments = (id) => {
  return axios({
      method:  'PATCH',
      url: "/approveComments",
      data: {commentId: id}
  })
}

// Approve comment
export const deleteUnapprovedComment = (id) => {
  return axios({
      method:  'DELETE',
      url: "/assessment-ref-user-comments",
      data: {id: id}
  })
}

export const setFavouriteStatus=(props)=>{
  console.log('434433443', props);
  return axios({
    method:'PATCH',
    url:'/updateFavouriteStatus',
    data:props
  })
}

export const addNewAssesmentUser=(props)=>{
  return axios({
    method:'POST',
    url:'/assessment/createUser',
    data:props
  })
}

export const editNewAssesmentUser=(props, id)=>{
  console.log('77777', props);
  return axios({
    method:'PATCH',
    url:'/assessment/updateUser/'+id,
    data:props
  })
}



