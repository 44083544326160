import React from 'react'
import { Route } from 'react-router-dom'
import { Routes } from 'react-router-dom'
import AccountList from './Account/AccountList'
import AccountsDetail from './Assessment/AccountsDetail'
import Assessment from './Assessment/Assessment'
import Dashboard from './Dashboard/Dashboard'
import Tags from './Tags/Tags'
import Login from './Login/Login'
import RecommendedTags from './Tags/RecommendedTags'
import UnapprovedComments from './Assessment/UnapprovedComments'
import ProductsListing from './Products/ProductsListing'
import ArchiveProductsListing from './Products/ArchiveProductsListing'

function Layout() {
  return (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path='/admin' element={<Dashboard/>}/>
          <Route path='/account' element={<AccountList/>}/>
          <Route path='/assessment' element={<Assessment/>}/>
          <Route path='/assessment-archieved' element={<Assessment pageType="archieved" />}/>
          <Route path='/tags' element={<Tags/>}/>
          <Route path='/recommendedTags' element={<RecommendedTags />}/>
          <Route path='/UnapprovedComments' element={<UnapprovedComments />}/>
          <Route path='/products' element={<ProductsListing />}/>
          <Route path='/archive-products' element={<ArchiveProductsListing />}/>
          <Route path="accounts" element={<AccountsDetail/>}/>
        </Routes>
      
  )
}

export default Layout