import React from 'react'
import { useEffect } from 'react'
import { CSVLink, CSVDownload } from 'react-csv'
import { assessmentUsers } from '../../Components/action/common'
import SideBar from '../Dashboard/SideBar'
import TopHeader from '../Dashboard/TopHeader'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setViewAccountsDetails } from '../../redux/slices/admin'
import Pagination from "react-js-pagination";
import { useState } from 'react'
import { useNavigate, Navigate } from 'react-router-dom';
import { authentication } from '../../Components/action/utilities';
import axios from "axios";


function AccountsDetail() {
  let cancelToken;
  const { state } = useLocation()
  const dispatch = useDispatch()
  //const { viewAccountsDetails } = useSelector(state => state.admin)
  const [viewAccountsDetails, setViewAccountDetails] = useState([]);
  const [limits, setLimits] = useState(null)
  const [totalRecords, setTotalRecords] = useState(null)
  const [totalPages, setTotalPages] = useState(null)
  const [emailList, setEmailList] = useState([]);
  const [csvLoading, setCsvLoading] = useState(false);
  const [params, setParams] = useState({ "refUserId": state?.assessment?.id, "pageNumber": 1, "limit": 10, sortOrder: "asc", sortBy: "email" })


  const fetchAssessmentUsersDetail = async (params) => {
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.")
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    try {

      //let response = await assessmentUsers({ "refUserId": state?.assessment?.id, "pageNumber": activePage, "limit": 10 })
      //let response = await assessmentUsers(params)
      let response = await axios.get(
        `/assessment-ref-registered-users`,
        { params: params, cancelToken: cancelToken.token } //Pass the cancel token to the current request
      )

      //dispatch(setViewAccountsDetails(response?.data?.responseData?.data))
      setViewAccountDetails(response?.data?.responseData?.data);
      setLimits(response?.data?.responseData?.limit)
      setTotalRecords(response?.data?.responseData?.totalRecords)
      setTotalPages(response?.data?.responseData?.totalPages)

    } catch (error) {

    }
  }

  const search = (value) => {
    let updatedParams = Object.assign(params, { searchText: value });
    fetchAssessmentUsersDetail(updatedParams);
    setParams(updatedParams);
  }

  const downloadCsv = async (event, done) => {
    try {
      setCsvLoading(true)
      let response = await assessmentUsers({ isCsv: 1, refUserId: state?.assessment?.id })
      setEmailList(response?.data?.responseData?.data);
      setCsvLoading(false)
      setTimeout(() => {
        done(false);
      }, 1000)

    } catch (error) {
      setCsvLoading(false)
    }
  }

  const handlePageChange = (pageNumber) => {
    let localParams = params;
    localParams = Object.assign(localParams, { pageNumber: pageNumber });
    setParams(localParams);
    fetchAssessmentUsersDetail(localParams)
    //setActivePage(pageNumber)
  }

  useEffect(() => {
    fetchAssessmentUsersDetail(params)
  }, [params])

  if (!authentication())
    return <Navigate to={'/'} />

  return (
    <>
      {/* <TopHeader /> */}
      <SideBar />
      <div className="content-page">
        {/* Start content */}
        <div className="UserLoader" />
        <div className="content userpage">
          <div className="container">
            {/* Page-Title */}
            <div className="row">
              <div className="col-sm-8">
                <h4 className="page-title">
                  <font style={{ verticalAlign: 'inherit' }}>Email List</font>
                </h4>
              </div>


            </div>

            <div className="row filter_options">

              {/*---Start Search User Form----------*/}
              <div className="col-md-4">
                <div className="form-group">

                  {/* <input style={{ padding: '3px 0px 5px 5px' }} type="text" className="form-controller" name="searchUser" placeholder="Search" onChange={(e) => search(e?.target?.value)} /> */}


                </div>
              </div>

              {/*---End Search User Form----------*/}
              <div className="col-md-8" style={{ textAlign: "end" }}>

                <button className="btn btn-primary" onClick={() => downloadCsv()}>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>
                      Export
                    </font>
                  </font>
                </button>
                {
                  emailList && emailList.length > 0 &&
                  <CSVDownload
                    data={emailList}
                    target="_blank"
                  >
                    Download me
                  </CSVDownload>
                }



              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <button className='btn ' onClick = {() => window.history.back()}>&larr;Back</button>
              </div>
            </div>
            <div className="userlisting row">
              <div className="table-responsive custom-center-ui scroll-table">
                <table id="loginUser" className="display dataTable" cellSpacing={0} width="100%" data-csrf="s34PayWawAyMqdwmzrHl8xkSSe3Xjab2V1YXSUFX" data-page="https://oksdevelopment.entwicklungslust.de/admin/toggleStatus">
                  <thead>
                    <tr>


                      <th>
                        <font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>E-mail</font></font>
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    {
                      viewAccountsDetails?.map((el) => {
                        return (
                          <tr rel="kW5" className="userInfo" id="usr_kW5" key={el?.userId}>
                            <td className="user_email">
                              <font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>{el?.email}</font></font>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
                <div style={{ textAlign: "center" }}>
                  <Pagination
                    activePage={parseInt(params.pageNumber)}
                    itemsCountPerPage={limits}
                    totalItemsCount={totalRecords}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
              <div className="row">
                <div style={{}} className="pagnations">
                  <br />
                </div>
              </div>
            </div>



          </div>
        </div>

      </div>


    </>
  )
}

export default AccountsDetail