import React from "react";
import { useState } from "react";
import { deleteTag } from "../../Components/action/tags";
import { getProducts, deleteProduct } from "../../Components/action/products";
import SideBar from "../Dashboard/SideBar";
import TopHeader from "../Dashboard/TopHeader";
import { useDispatch, useSelector } from "react-redux";
//import { setTagList } from '../../redux/slices/admin'
import { useEffect } from "react";
import Pagination from "react-js-pagination";
import { authentication } from "../../Components/action/utilities";
import { useNavigate, Navigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Select from 'react-select';
import axios from "axios";
var CancelToken = axios.CancelToken;
var cancel;

function ProductsListing() {
  const dispatch = useDispatch();

  const [tagObject, setTagObject] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [parentTags, setParentTags] = useState([]);
  const [limits, setLimits] = useState(null);
  const [productList, setProductList] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState(null);

  const [totalRecords, setTotalRecords] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [params, setParams] = useState({
    pageNumber: 1,
    archived: false
  });
  const [timer, setTimer] = useState(null);

  const fetchProductsListing = (params) => {
    if (typeof cancel == "function") {
      cancel();
    }
    axios({
      method: 'GET',
      params: params,
      url: "/getProducts",
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      })

    }).then((response) => {
      setProductList(response?.data?.responseData);
      setLimits(response?.data?.responseData?.limit);
      setTotalRecords(response?.data?.responseData?.totalRecords);
      setTotalPages(response?.data?.responseData?.totalPages);
      setCurrentPage(response?.data?.responseData?.page ?? currentPage);
    })
  }

  const onDeleteProduct = (obj) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              let response = await deleteProduct(obj.id);
              fetchProductsListing(params);
              console.log(response, "response");
            } catch ({ response }) { }
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const handlePageChange = (pageNumber) => {
    let localParams = JSON.parse(JSON.stringify(params));
    localParams = Object.assign(localParams, { pageNumber: pageNumber });
    setParams(localParams);
    fetchProductsListing(localParams);
  };

  useEffect(() => {
    fetchProductsListing(params);
  }, [activePage]);

  const search = (value) => {
    let updatedParams = Object.assign(params, { searchText: value, pageNumber: 1 });
    if(!value){
      delete updatedParams.searchText;
    }
    clearTimeout(timer)
    const newTimer = setTimeout(() => {
      fetchProductsListing(updatedParams);
    }, 500)
    setTimer(newTimer)
    setParams(updatedParams);

  }

  if (!authentication()) return <Navigate to={"/"} />;


  return (
    <>
      {/* <TopHeader /> */}
      <SideBar />

      <div className="content-page">
        {/* Start content */}
        <div className="UserLoader" />
        <div className="content userpage">
          <div className="container">
            <div className="filter_options">
              <h4 className="page-title">
                <font style={{ verticalAlign: "inherit" }}>
                  Products listing
                </font>
              </h4>
              <div className='filter_options_inner'>
                <div className="form-group">
                  <input style={{ padding: '3px 0px 5px 5px' }} type="text" className="form-control" name="searchUser" placeholder="Search" onChange={(e) => search(e?.target?.value)} />
                </div>
              </div>
            </div>
            

            <div className="userlisting row">
              <div className="table-responsive custom-center-ui tagManagment">
                <table
                  id="loginUser"
                  className="display dataTable accountManagement "
                  cellSpacing={0}
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th className="th-account-name">
                        <font style={{ verticalAlign: "inherit" }}>Title</font>
                      </th>
                      <th className="th-account-name">
                        <font style={{ verticalAlign: "inherit" }}>Digistore link</font>
                      </th>

                      <th className="th-action">
                        <font style={{ verticalAlign: "inherit" }}>
                          Actions
                        </font>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {productList?.data?.length == 0 && (
                      <tr>
                        <td colSpan={"100%"}>'No Data Found!'</td>
                      </tr>
                    )}
                    {productList?.data?.map((product, index) => {
                      return (
                        <tr className="userInfo">
                          <td>
                            {(parseInt(currentPage) - 1) * parseInt(limits) +
                              (index + 1)}
                          </td>
                          <td className="user_unique_id">{product?.product_title}</td>
                          <td className="user_unique_id">{product?.digistore_link}</td>
                          <td className="td-action">
                            <span className="action-span">
                              
                              <img
                                src="./images/delete.png"
                                style={{ cursor: "pointer" }}
                                onClick={() => onDeleteProduct(product)}
                              />

                              {/* <i className="fa fa-edit" style={{ cursor: "pointer" }}></i> */}
                              {/* <i className='fa fa-trash' onClick={() => onDeleteTag(tag)}></i> */}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

              </div>
              {totalPages > 1 && (
                <div style={{ textAlign: "center" }}>
                  <Pagination
                    activePage={parseInt(params.pageNumber)}
                    itemsCountPerPage={limits}
                    totalItemsCount={totalRecords}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                  />
                </div>
              )}
              <div className="row">
                <div style={{}} className="pagnations">
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}

export default ProductsListing;
