import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

function SideBar() {
  const navigate=useNavigate()
  const [isActive,setIsActive]=useState(false);
  const { loggedInData } = useSelector(state => state.admin);
  useEffect(()=>{
   let path=window.location.pathname
   if(path == '/account'){
    setIsActive(true)
   }else if(path == '/admin'){
    setIsActive(true)
   }else if(path == '/assessment'){
    setIsActive(true)
   }else if(path == '/unapprovedComments'){
    setIsActive(true)
   }else if(path == '/tags'){
    setIsActive(true)
   }else if(path == '/recommendedTags'){
    setIsActive(true)
   }
  },[])
  return (
    <div className="left side-menu">
    <div className="sidebar-inner">
      {/*- Divider */}
      <div id="sidebar-menu">
        <ul>
      <div className="text-center sidebar_logo">
        <a  className="logo"><img className="img-responsive" src="./images/logo-200x200.svg" />
        {/* <span><font style={{verticalAlign: 'inherit'}}>TDB Speaker Agency</font></span> */}
        </a>
          <li className="text-muted menu-title"><font style={{verticalAlign: 'inherit'}}>Elite Speaker</font></li>
        </div>
          {
            loggedInData?.role == "SUPER_ADMIN" &&
            <>
              <li><Link  className={`waves-effect waves-light ${isActive == true && window.location.pathname == '/admin' ? 'active': ''}`} to = '/admin'><i className="fa fa-user" /> 
              <span>User</span>
               </Link></li>
              <li><Link  className={`waves-effect waves-light ${isActive == true && window.location.pathname == '/account' ? 'active': ''}`} to = '/account'><i className="fa fa-lock" /> <span>Account</span> </Link></li>
              
            </>
          }
          {
            (loggedInData?.role == "SUPER_ADMIN" || loggedInData?.role == "MANAGER") &&
            <>
            <li><Link  className={`waves-effect waves-light ${isActive == true && window.location.pathname == '/unapprovedComments' ? 'active': ''}`} to = '/unapprovedComments'><i className="fa fa-tags" /> <span>Unapproved Comments</span> </Link></li>
            <li><Link  className={`waves-effect waves-light ${isActive == true && window.location.pathname == '/recommendedTags' ? 'active': ''}`} to = '/recommendedTags'><i className="fa fa-tags" /> <span>Recommended Tags</span> </Link></li>
            <li><Link  className={`waves-effect waves-light ${isActive == true && window.location.pathname == '/tags' ? 'active': ''}`} to = '/tags'><i className="fa fa-tags" /> <span>Tags</span> </Link></li>
            </>
            
          }
          <li><Link  className={`waves-effect waves-light ${isActive == true && window.location.pathname == '/assessment' ? 'active': ''}`} to = '/assessment'><i className="fa fa-id-badge" /> <span><font style={{verticalAlign: 'inherit'}}>Assessment</font></span></Link></li>
          {
            (loggedInData?.role == "SUPER_ADMIN" || loggedInData?.role == "MANAGER") &&
            <li><Link  className={`waves-effect waves-light ${isActive == true && window.location.pathname == '/assessment-archieved' ? 'active': ''}`} to = '/assessment-archieved'><i className="fa fa-archive" /> <span><font style={{verticalAlign: 'inherit'}}>Archived assessment</font></span></Link></li>
          }

          {
            (loggedInData?.role == "SUPER_ADMIN" || loggedInData?.role == "MANAGER") &&
            <li><Link  className={`waves-effect waves-light ${isActive == true && window.location.pathname == '/products' ? 'active': ''}`} to = '/products'><i className="fa fa-archive" /> <span><font style={{verticalAlign: 'inherit'}}>Products</font></span></Link></li>
          }

          {
            (loggedInData?.role == "SUPER_ADMIN" || loggedInData?.role == "MANAGER") &&
            <li><Link  className={`waves-effect waves-light ${isActive == true && window.location.pathname == '/archive-products' ? 'active': ''}`} to = '/archive-products'><i className="fa fa-archive" /> <span><font style={{verticalAlign: 'inherit'}}>Archive Products</font></span></Link></li>
          }

          
        </ul>
        <div className="clearfix" />
        <ul>
        <li className='logout_wrpr' onClick={()=>{localStorage.clear();navigate('/')}}><a href='#'><i className="fa fa-archive" /> <span><font style={{verticalAlign: 'inherit'}}>Logout</font></span></a></li>
        </ul>
      </div>
      <div className="clearfix" />
    </div>
  </div>
  )
}

export default SideBar