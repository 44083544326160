import React, { useEffect, useState } from 'react'
import { Route, useNavigate } from 'react-router-dom'
import { Routes } from 'react-router-dom'
import AccountList from './Account/AccountList'
import AccountsDetail from './Assessment/AccountsDetail'
import Assessment from './Assessment/Assessment'
import Dashboard from './Dashboard/Dashboard'
import Tags from './Tags/Tags'
import Login from './Login/Login'
import RecommendedTags from './Tags/RecommendedTags'
import UnapprovedComments from './Assessment/UnapprovedComments'
import ProductsListing from './Products/ProductsListing'
import ArchiveProductsListing from './Products/ArchiveProductsListing'

import PageNotFound from '../Components/Common/PageNotFound'

function Layout() {

  const navigate= useNavigate()
  // const [timeTokenFlag,setTimeTokenFlag] = useState(false)

  // const loginTime= localStorage.getItem('timeToken')

  //   useEffect(() => {
  //       if (loginTime) {
  //           const interval = setInterval(() => {
  //               const currentTime = new Date();
  //               const loginDate = new Date(loginTime);
                
  //               // Check if more than 1 hour has passed since login
  //               const diffInMilliseconds = currentTime - loginDate;
  //               const diffInHours = diffInMilliseconds / (1000 *60*60);
                
  //               if (diffInHours >= 1) {
  //                   // If more than 1 hour has passed, log the user out
  //                   // setTimeTokenFlag(true)
  //                   clearInterval(interval); // Clear interval after logout
  //                   navigate('/')
  //                   localStorage.clear()
  //               }
  //           }, 60000); // Check every minute

  //           return () => clearInterval(interval);
  //       }
  //   }, [loginTime]);
  return (
        <Routes>
          <Route path="/" element={<Login />} />
         <Route path='/admin' element={<Dashboard/>}/>
          <Route path='/account' element={<AccountList/>}/>
          <Route path='/assessment' element={<Assessment/>}/>
          <Route path='/assessment-archieved' element={<Assessment pageType="archieved" />}/>
          <Route path='/tags' element={<Tags/>}/>
          <Route path='/recommendedTags' element={<RecommendedTags />}/>
          <Route path='/UnapprovedComments' element={<UnapprovedComments />}/>
          <Route path='/products' element={<ProductsListing />}/>
          <Route path='/archive-products' element={<ArchiveProductsListing />}/>
          <Route path="accounts" element={<AccountsDetail/>}/> 
          <Route path='*' element={<PageNotFound/>}/>
        </Routes>
      
  )
}

export default Layout